import React from 'react'
import { FORTH_CARD } from '../../Config/CardConstant';

const PhilosophySection = () => {
  return (
    <div className="grid lg:grid-cols-4 grid-cols-2 bg-primary">
      {FORTH_CARD.map((item, index: number) => {
        return (
          <div
            key={index}
            className={`${index === 0 &&
              "col-span-2 border-none rounded-l-none"
              } flex justify-center items-center border-dotted rounded-3xl border border-gray_700 sm:h-80 h-40 ${item.style
              }`}
          >
            <div>
              {item.icon && (
                <div className="flex justify-center">
                  <div>
                    <img
                      src={item.icon}
                      className="xl:w-full w-2/3"
                      alt="Icon"
                    />
                  </div>
                </div>
              )}
              <p
                className={`font-jersey ${index === 0
                  ? "xl:text-64 md:text-5xl text-4xl"
                  : "xl:text-xl md:text-base text-sm leading-none tracking-tightest"
                  }`}
              >
                {item.content}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  )
}

export default PhilosophySection