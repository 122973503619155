import { useEffect } from "react";
import { Preloader } from "../../Components/Preloader/Preloader";
import { useLoading } from "../../context/loadingContext";
import TeamSections from "../sections/TeamSections";
import PerceptionSection from "../sections/PerceptionSection";
import CaseSection from "../sections/CaseSection";
import PhilosophySection from "../sections/PhilosophySection";
import DigitalArtSection from "../sections/DigitalArtSection";
import CreativitySection from "../sections/CreativitySection";
import ProblemSection from "../sections/ProblemSection";
import EqualsSection from "../sections/EqualsSection";
import ServiceSection from "../sections/ServiceSection";
import HeroSection from "../sections/HeroSection";
import SolutionSection from "../sections/SolutionSection";
export const Home: React.FC = () => {
  const { isLoading, showLoader, hideLoader } = useLoading();

  const handleEnterClick = (): void => {
    hideLoader();
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    showLoader();
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <Preloader handleEnterClick={handleEnterClick} />;
  }

  return (
    <div>
      <HeroSection />
      <ServiceSection />
      <EqualsSection />
      <ProblemSection />
      <SolutionSection />
      <CreativitySection />
      <DigitalArtSection />
      <PhilosophySection />
      <CaseSection />
      <TeamSections />
      <PerceptionSection />
    </div>
  );
};
