import React from 'react'
import { SOLUTION_CARDS } from '../../Config/CardConstant'
import SolutionItem from '../../Components/common/SolutionItem'

const SolutionSection = () => {
  return (
    <div className='pb-32 bg-primary flex items-center justify-center'>
      <div className='max-w-[80%] md:max-w-[1728px] md:px-36 flex flex-col items-center gap-8'>
        <div className='flex flex-col items-center'>
          <span className='font-jersey text-black md:text-10xl text-7xl leading-none tracking-[-3px]'>
            Solution
          </span>
          <span className='font-istok text-section_at md:text-xl text-md font-bold leading-6 md:w-[95%] w-[80%] text-center tracking-[-1px]'>
            Check below of how we are solving the biggest problem with creators businesses.
          </span>
        </div>
        <div className='flex items-center gap-6 max-w-[1440px] md:flex-row flex-col'>
          {SOLUTION_CARDS.map((item, index) => {
            return (
              <SolutionItem
                key={index}
                item={item}
              />
            )
          })}
        </div>
        <a
          className="bg-header_button hover:bg-red-300 cursor-pointer text-white xl:px-13 sm:px-10 px-6 xl:py-3.5 sm:py-3 py-1 max-w-[400px] rounded-[30px] self-end mt-5"
          // onClick={props.toggleModal}
          href="mailto:info@jokerwebdesign.com"
        >
          <p className="xl:text-64 lg:text-5xl xs:text-4xl text-3xl leading-none font-jersey">
            Get a Quote
          </p>
        </a>
      </div>
    </div>
  )
}

export default SolutionSection