import React from 'react'
import Slider from 'react-slick';
import { CAROUSEL_ITEMS } from '../../Config/ImgConstant';
import { NextArrow } from '../../Components/Arrow/NextArrow';
import { PrevArrow } from '../../Components/Arrow/PrevArrow';
import { Cat } from '../../Config/Images';

const CaseSection = () => {
  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="bg-primary lg:py-40 md:py-20 sm:py-10 py-4">
      <div className="xl:px-36 md:px-24 px-12">
        <p>
          <span className="font-jersey sm:text-64 text-3xl leading-64 tracking-tightest">
            Case studies
          </span>
          &nbsp;
          <span className="font-jersey sm:text-64 text-3xl leading-64 tracking-tightest">
            &lt;|&gt;
          </span>
        </p>
        <div className="font-istok lg:text-xl sm:text-lg xs:text-base text-xs text-section_at lg:leading-25 leading-none tracking-tightest sm:pt-6 pt-2">
          <p>
            Explore our case studies to see real-world examples
            of how we've helped clients achieve their goals
            through innovative solutions and measurable results.
          </p>
        </div>
      </div>
      <div className="relative z-30 sm:my-10 my-3">
        <Slider {...settings}>
          {CAROUSEL_ITEMS.map((item, index) => {
            return (
              <div
                className="sm:px-5 px-2 pb-24 lg:aspect-carousel aspect-carousel_lg sm:!w-23vw !w-43vw"
                key={index}
              >
                <div className="w-full h-full rounded-3xl border shadow-md shadow-black border-black overflow-hidden md:min-h-[465px]">
                  <img
                    src={item.image}
                    className="h-full w-full"
                    alt={`Plane-${index}`}
                  />
                </div>
              </div>
            );
          })}
        </Slider>
        <div className="absolute sm:left-[68.5vw] left-[42.5vw] top-0 sm:px-5 px-2 pb-24 lg:aspect-carousel_cat md:aspect-[10/13.8] aspect-carousel_lg sm:!w-24vw !w-44vw">
          <div className="w-full h-full relative flex justify-center bg-section_maincard rounded-3xl md:min-h-[465px] ">
            <div className="absolute w-1/2 flex justify-center bottom-0">
              <img
                src={Cat}
                alt="Cat"
                className="w-full h-full before:bg-white md:max-w-[151px] md:max-h-[159px] max-w-12 max-h-12"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CaseSection