import React from 'react'
import { PRIMARY_CARD } from '../../Config/CardConstant';
import { Symbol } from '../../Element/SeverSectionCardSymbol';

const ServiceSection = () => {
  return (
    <div className="xl:px-36 md:px-24 px-12 xl:py-39 md:py-24 py-12 bg-primary flex justify-center">
      <div className='flex flex-col justify-center max-w-[1440px]'>
        <div className="flex justify-center">
          <p className="font-jersey 2xl:text-7xl xl:text-6xl md:text-5xl text-4xl text-center leading-none md:w-4/5 w-full">
          Transform Your Online Presence with Custom Web
            <span className="text-section_best"> Design Solutions</span>.
          </p>
        </div>
        <div className="flex justify-center font-istok 2xl:text-xl text-base text-section_at md:pt-6 pt-4 md:pb-16 pb-8">
          <p className="md:w-1/2 w-full text-center font-bold">
            At JokerwebDesign.com we search and find the uniqueness
            for every new website we build
          </p>
        </div>
        <div className="grid 2xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
          {PRIMARY_CARD.map((item, index) => {
            return (
              <div
                className={`flex flex-col 3xl:h-64 md:h-72 h-52 ${item.background} 2xl:p-8 md:p-6 p-4 rounded-3xl`}
                key={index}
              >
                <p className="flex-none font-istok md:text-2xl text-lg font-bold tracking-tightest">
                  {item.title}
                </p>
                <p className="flex-1 font-istok md:text-base text-xs tracking-tightest pt-2">
                  {item.content}
                </p>
                <div
                  className={`relative z-0 flex-none md:h-9 h-4 md:w-9 w-4 rounded-full border border-black ${item.circleColor} hover:bg-circle`}
                >
                  <Symbol color="black" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  )
}

export default ServiceSection