import React from 'react'
import { DevTitle, EvelopTitle, Pig, Planet, Shy, WeTitle } from '../../Config/Images';
import { IMG_ITEMS, LETTER_ITEMS, LOGO_ITEMS } from '../../Config/ImgConstant';

const HeroSection = () => {
  return (
    <div className="grid md:grid-cols-6 sm:grid-cols-4 grid-cols-2 bg-primary">
        {IMG_ITEMS.map((item, index: number) => {
          return (
            <div key={index}>
              <img
                src={item.name}
                className="w-full h-full"
                alt="img"
              />
            </div>
          );
        })}
        <div className="col-start-1 bg-black w-full aspect-square z-30"></div>
        <div className="relative bg-radial w-full aspect-square lg:pl-7 pl-3 z-30">
          <div className="absolute top-1 w-full">
            <div className=" w-1/3">
              <img
                src={WeTitle}
                className="w-full"
                alt="first-title"
              />
            </div>
            <div className="relative mt-1.5">
              <div className="w-2/3 h-auto">
                <img
                  src={DevTitle}
                  className="w-full"
                  alt="second-title"
                />
              </div>
              <div className="absolute top-3/4 w-5/6 h-auto">
                <img
                  src={EvelopTitle}
                  className="w-full"
                  alt="third-title"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center gap-x-20 md:col-span-4 col-span-2 group">
          {LOGO_ITEMS.map((item, index) => {
            return (
              <div
                className="relative z-20 animate-example animation-running group-hover:animation-paused cursor-pointer md:block hidden"
                key={index}
              >
                <img
                  src={item.name}
                  className="h-full w-full"
                  alt="Logo"
                />
              </div>
            );
          })}
        </div>
        <div>
          <img src={Pig} className="w-full h-full" alt="Pig" />
        </div>
        <div className="flex flex-col">
          <p className="font-inter 2xl:text-5xl xl:text-4xl text-3xl font-bold leading-none text-section_best pl-7 mt-1">
            the best
          </p>
          <p className="text-center font-junge 2xl:text-64 xl:text-5xl text-4xl text-section_websites py-3">
            websites
          </p>
          <div className="flex justify-end pr-6">
            <p className="font-inter 2xl:text-40 xl:text-3xl text-2xl font-black text-section_on">
              on the
            </p>
          </div>
        </div>
        <div className="flex flex-col relative">
          <img
            src={Planet}
            className="h-full w-full absolute z-0"
            alt="Planet"
          />
          {LETTER_ITEMS.map((item, index) => {
            return (
              <div
                key={index}
                className="flex-1 2xl:text-86 text-6xl text-section_planet font-bold z-10 text-right  content-center pr-3"
              >
                {item.letter}
              </div>
            );
          })}
        </div>
        <div>
          <img src={Shy} className="h-full w-full" alt="Shy" />
        </div>
      </div>
  )
}

export default HeroSection