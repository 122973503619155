import Arrow from "../Assets/Img/arrow.svg";
import BeachOne from "../Assets/Img/beach_one.png";
import BeachTwo from "../Assets/Img/beach_two.png";
import BeachThree from "../Assets/Img/beach_three.png";
import Earth from "../Assets/Img/earth.svg";
import Foot from "../Assets/Img/foot.svg";
import Pen from "../Assets/Img/pen.svg";
import Phone from "../Assets/Img/phone.svg";
import Tank from "../Assets/Img/tank.svg";
import SolutionOne from "../Assets/Img/solution-1.png";
import SolutionTwo from "../Assets/Img/solution-2.png";
import SolutionThree from "../Assets/Img/solution-3.png";
import { SolutionCard } from "../Types/CardItemTypes";

export const PRIMARY_CARD = [
  {
    id: "1",
    background: "bg-section_maincard",
    title: "Website redesign",
    content:
      "Transform your online presence with our expert website redesign service, tailored to boost engagement and reflect the unique essence of your brand.",
    circleColor: "bg-section_maincard",
    arrow: Arrow,
  },
  {
    id: "2",
    background: "bg-section_maincard",
    title: "Web design and UI",
    content:
      "Enhance your brand with stunning web design and seamless UI for an unforgettable user experience.",
    circleColor: "bg-section_maincard",
    arrow: Arrow,
  },
  {
    id: "3",
    background: "bg-section_maincard",
    title: "UX design",
    content:
      "Elevate your brand with exceptional UX design for a smooth, engaging user journey.",
    circleColor: "bg-section_maincard",
    arrow: Arrow,
  },
  {
    id: "4",
    background: "bg-section_maincard",
    title: "Web development",
    content:
      "Boost your brand with powerful web development for a fast, seamless online experience.",
    circleColor: "bg-section_maincard",
    arrow: Arrow,
  },
  {
    id: "5",
    background: "bg-section_maincard",
    title: "Paid social",
    content:
      "We can help your business drive enormous amount of traffic to your website via paid social platforms like Google and Facebook.",
    circleColor: "bg-section_maincard",
    arrow: Arrow,
  },
  {
    id: "6",
    background: "bg-section_maincard",
    title: "Search Engine Optimization",
    content:
      "Drive targeted traffic to your website with our expert SEO services, designed to enhance rankings and maximize ROI.",
    circleColor: "bg-section_maincard",
    arrow: Arrow,
  },
  {
    id: "7",
    background: "bg-section_maincard",
    title: "Email marketing",
    content:
      "Engage your audience and drive conversions with personalized, results-driven email marketing.",
    circleColor: "bg-section_maincard",
    arrow: Arrow,
  },
  {
    id: "8",
    background: "bg-section_radial",
    title: "Chat GPT and AI services",
    content:
      "Unlock smarter solutions and streamline operations with our cutting-edge AI services tailored to your business needs.",
    circleColor: "",
    arrow: Arrow,
  },
];

export const SECOND_CARD = [
  {
    id: "1",
    title: "Seamless Design, Perfectly Reflecting Your Brand",
    content:
      "From initial concept to final launch, we ensure a seamless experience that reflects your brand's identity.",
  },
  {
    id: "2",
    title: "Modern Websites That Engage and Deliver Results",
    content:
      "With a focus on modern design and functionality, we craft websites that not only look great but also drive meaningful engagement and measurable results.",
  },
];

export const THIRD_CARD = [
  {
    id: "1",
    img: BeachOne,
    title: "Digital Strategy & Discovery",
    content:
      "Before establishing an approach, it’s essential to first define the brand’s overarching goal. Following that, we must conduct a comprehensive analysis, including content evaluation, search engine keyword research, competitor assessment, identification of suitable engagement channels, and the development of a strategic plan to achieve these objectives.",
  },
  {
    id: "2",
    img: BeachTwo,
    title: "Brand-first Approach",
    content:
      "At the heart of all our solutions lies a brand-first approach. We understand that first impressions are crucial, as there’s only one opportunity to capture attention. In today’s world of constant stimulation, if we fail to engage customers with a visually striking, innovative, and intelligently designed yet straightforward experience, they’ll quickly move on to the next big thing.",
  },
  {
    id: "3",
    img: BeachThree,
    title: "Custom Development Solutions",
    content:
      "We reject the idea of one-size-fits-all solutions. Instead, we craft custom-tailored strategies designed to meet our clients' unique needs, align with their business processes, and streamline their digital workflows.",
  },
];

export const FORTH_CARD = [
  {
    id: "1",
    style: "bg-gray_600",
    content: "Our Philosophy",
  },
  {
    id: "2",
    style: "bg-gray_500",
    content: "Every creature must live.",
    icon: Foot,
  },
  {
    id: "3",
    style: "bg-slate_100",
    content: "Mobile is the new Boss.",
    icon: Phone,
  },
  {
    id: "4",
    style: "bg-slate_100",
    content: "",
  },
  {
    id: "5",
    style: "bg-slate_100",
    content: "War must stop",
    icon: Tank,
  },
  {
    id: "6",
    style: "bg-slate_100",
    content: "Design should help people progress.",
    icon: Pen,
  },
  {
    id: "7",
    style: "bg-slate_100",
    content: "Internet is the future.",
    icon: Earth,
  },
];

export const SOLUTION_CARDS: SolutionCard[] = [
  {
    icon: SolutionOne,
    title: {
      color: 'text-red_600',
      text: 'Fair pricing'
    },
    content: {
      color: 'text-gray_900',
      text: 'Our web design packages are thoughtfully crafted to offer high-quality solutions at a fair and transparent price, ensuring you get the most value for your investment. We believe in honest pricing, with no hidden costs, so you can focus on growing your business with confidence.'
    },
    sectionBg: 'bg-orange_200'
  },
  {
    icon: SolutionTwo,
    title: {
      color: 'text-cyan_500',
      text: 'Created',
      subText: 'Uniquely'
    },
    content: {
      color: 'text-black',
      text: 'At our agency, we believe every brand deserves a website that reflects its unique identity and vision. That’s why we prioritize custom design solutions, carefully crafted from scratch to meet your specific needs. By avoiding generic templates, we ensure your website stands out, combining creativity with functionality to deliver a truly one-of-a-kind online presence.'
    },
    sectionBg: 'bg-cyan_100'
  },
  {
    icon: SolutionThree,
    title: {
      color: 'text-purple_700',
      text: 'Innovation',
      subText: 'Unleashed'
    },
    content: {
      color: 'text-gray_900',
      text: 'We champion true innovation by embracing a custom-first approach, where originality takes center stage. Our process is designed to prioritize creativity and fresh perspectives, ensuring every project breaks away from the ordinary and delivers something truly remarkable—on time, without compromise.'
    },
    sectionBg: 'bg-orange_100'
  },
]