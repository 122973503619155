import React from 'react'
import { SolutionCard } from '../../Types/CardItemTypes'

const SolutionItem = ({ item }: {item: SolutionCard}) => {
  return (
    <div className={`${item.sectionBg} border border-black rounded-[30px] py-6 md:px-13 px-4 flex flex-col gap-2.5 md:max-w-[464px] max-w-[80%]`}>
      <div className='flex items-center gap-6 min-w-[409px]'>
        <img 
          src={item.icon}
          alt='solution'
          className='max-w-[70px] max-h-[70px] object-cover w-full h-full'
        />
        <div className='flex flex-col'>
          <span className={`${item.title.color} font-jersey md:text-[72px] text-[56px] -tracking-[3px]`}>
            {item.title.text}
          </span>
          <span className={`${item.title.color} font-jersey md:text-[48px] text-[32px] -mt-[30px] md:-tracking-[3px]`}>
            {item.title.subText}
          </span>
        </div>
      </div>
      <p className={`${item.content.color} md:text-lg leading-8`}>
        {item.content.text}
      </p>
    </div>  
  )
}

export default SolutionItem