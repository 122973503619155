import React from 'react'
import YouTubePlayer from 'react-youtube'
import { PopupButton } from 'react-calendly';

const opts = {
  width: "100%",
  height: "100%",
  playerVars: { autoplay: 0 },
};

const CreativitySection = () => {
  const videoReady = (event: { target: { pauseVideo: () => void } }) => {
    event.target.pauseVideo();
  };

  return (
    <div className="relative bg-primary">
      <div className="absolute bg-zinc_950 w-full -top-12 rounded-t-1/2 p-12 z-0"></div>
      <div className="relative w-full z-10 flex flex-col gap-4 bg-rock bg-no-repeat bg-cover bg-zinc_950 pb-32">
        <div className='flex flex-col gap-6 items-center'>
          <div className="font-jersey 2xl:text-10xl xl:text-8xl sm:text-6xl text-5xl text-white 2xl:leading-100 leading-40 text-center tracking-tightest w-2/3">
            Get ready
          </div>
          <div className="font-jersey 2xl:text-6xl xl:text-5xl lg:text-4xl md:text-3xl text-2xl text-white text-center 2xl:leading-100 xl:leading-75 lg:leading-none sm:leading-2 tracking-tightest">
            to grow your busine$$!
          </div>
        </div>
        <div className='flex md:flex-row flex-col gap-4 w-full md:px-36 px-6 justify-center'>
          <div className="w-full !max-w-[960px] flex flex-col items-center ">
            <div className="bg-black font-istok xl:text-xl text-sm text-white tracking-tightest sm:leading-25 leading-1 px-6 xl:py-7 sm:py-4 py-3 w-full">
              Elevate your business with our expert web design and development services, tailored to meet your unique needs. We create user-friendly, visually stunning websites designed to engage your audience and drive measurable results. Let us help you build a strong online presence that sets your brand apart and fuels your growth.
            </div>
            <div className="z-20 py-3 mt-3 w-full">
              <div className="w-full md:h-32vw h-100vw rounded-12 overflow-hidden m-auto max-h-[537px]">
                <YouTubePlayer
                  className="h-full"
                  opts={opts}
                  onReady={videoReady}
                  videoId="LpvypuuNZcE"
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-6 max-w-[464px]'>
            <div className='bg-black border border-white py-6 md:px-28 flex justify-center'>
              <span className="font-jersey 2xl:text-6xl xl:text-5xl lg:text-4xl text-3xl text-white text-center tracking-tightest">
                Seize your
                potential
              </span>
            </div>
            <div className='bg-gray_200 md:p-7 p-4'>
              <span className="font-istok md:text-xl text-md text-black text-center tracking-tightest">
                Book a virtual meeting today to learn how JokerwebDesign.com design and development team can help you achieve your goals.
              </span>
            </div>
            <PopupButton
              url="https://calendly.com/jokerwebdesign-info"
              rootElement={document.getElementById('root') as any}
              text="LET’S TALK"
              className="bg-second_orange cursor-pointer xl:px-13 sm:px-10 px-6 xl:py-6 py-3 text-white text-[32px] font-bold leading-6 text-center"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreativitySection