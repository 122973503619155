import React, { useState } from 'react'
import { TEAM_ITEMS } from '../../Config/ImgConstant';
import { Team, Ticket, Left, Right, Tree } from "../../Config/Images";
const TeamSections = () => {
  const [teamMembers, setTeamMembers] = useState(TEAM_ITEMS);

  const showNextTeamMember = () => {
    setTeamMembers((prevMembers) => {
      const selectItemIndex = prevMembers.findIndex(
        (item) => item.selected === true
      );

      const updatedMembers = prevMembers.map((item, index) => ({
        ...item,
        selected: index === (selectItemIndex + 1) % prevMembers.length,
      }));

      return updatedMembers;
    });
  };

  const showPrevTeamMember = () => {
    setTeamMembers((prevMembers) => {
      const selectItemIndex = prevMembers.findIndex(
        (item) => item.selected === true
      );

      const updatedMembers = prevMembers.map((item, index) => ({
        ...item,
        selected: index === (selectItemIndex + 3) % prevMembers.length,
      }));

      return updatedMembers;
    });
  };

  return (
    <div className="relative bg-cover bg-center z-0 xl:px-36 md:px-24 px-12 flex justify-center">
      <div className='flex flex-col max-w-[1440px] relative'>
        <div className='bg-street absolute top-0 left-0 max-w-[1100px] max-h-[946px] w-full h-full -z-10' />
        <div className="relative w-full text-center xl:py-4 py-2">
          <p className="font-jersey 2xl:text-64 xl:text-5xl text-4xl 2xl:leading-64 leading-none tracking-tightest">
            The &nbsp;
            <span className="font-jersey 2xl:text-10xl xl:text-8xl text-6xl 2xl:leading-150 leading-none tracking-tightest">
              team
            </span>
            &nbsp; of Jokers
          </p>
        </div>
        {teamMembers.map((item, index) => {
          return (
            item.selected === true && (
              <div
                className="grid md:grid-cols-2 grid-cols-1 xl:gap-x-40 lg:gap-x-24 gap-x-10 z-10 md:gap-y-0 gap-y-8 xl:pt-6 pt-4 justify-center"
                key={index}
              >
                <div>
                  <div className="flex justify-between bg-black rounded-2xl w-full px-6 xl:py-4 py-1">
                    <div className="flex justify-center items-center font-istok font-bold xl:text-2xl text-xs text-white tracking-tightest">
                      {item.title}
                    </div>
                    <div>
                      <img src={Team} alt="Team" />
                    </div>
                  </div>
                  <div className="flex flex-col 2xl:gap-y-8 gap-y-6 xl:pt-9 pt-5 px-4">
                    {item.first_content.map(
                      (item, index) => {
                        return (
                          <p
                            className="font-istok 2xl:text-xl lg:text-lg text-sm tracking-tightest"
                            key={index}
                          >
                            {item}
                          </p>
                        );
                      }
                    )}
                    {item.second_content.map(
                      (item, index) => {
                        return (
                          <div
                            key={index}
                            className="flex"
                          >
                            <div>
                              <img
                                src={Ticket}
                                className="xl:h-full h-2/3 xl:w-full w-2/3"
                                alt="Tick"
                              />
                            </div>
                            <p className="font-istok 2xl:text-xl lg:text-lg text-sm pl-2">
                              {item}
                            </p>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="flex flex-col bg-teammember bg-center bg-cover bg-no-repeat rounded-3xl border-4 border-black max-w-[578px]">
                  <div className="flex justify-between items-center xl:px-6 px-4 xl:pt-12 md:pt-9 pt-3 xl:pb-6 md:pb-4 pb-2 border-b border-slate_800 z-10">
                    <p className="font-istok 2xl:text-64 xl:text-5xl md:text-3xl text-xl font-bold tracking-tightest">
                      {item.name}
                    </p>
                    <div className="flex xl:gap-6 gap-4">
                      <div className="2xl:h-20 xl:h-14 sm:h-10 h-8 2xl:w-20 xl:w-14 sm:w-10 w-8">
                        <img
                          src={Left}
                          className="h-full w-full"
                          alt="Left"
                          onClick={showPrevTeamMember}
                        />
                      </div>
                      <div className="2xl:h-20 xl:h-14 sm:h-10 h-8 2xl:w-20 xl:w-14 sm:w-10 w-8">
                        <img
                          src={Right}
                          className="h-full w-full"
                          alt="Right"
                          onClick={showNextTeamMember}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-end items-center pt-10">
                    <div className="b_xl:h-[490px] lg:h-[300px] xs:h-[260px] b_xl:w-[450px] lg:w-[260px] xs:w-[230px] w-[200px]">
                      <img
                        src={item.img}
                        className="h-full w-full"
                        alt="TeamMember"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          );
        })}
        <div className="absolute left-0 -bottom-20 md:block hidden">
          <img
            src={Tree}
            className="xl:h-2/3 h-1/3 xl:w-2/3 w-1/3 max-w-[300px] max-h-[380px]"
            alt="tree"
          />
        </div>
      </div>
    </div>
  )
}

export default TeamSections