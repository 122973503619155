import React from 'react'
import { Chain, Circle, Fall, Flower, Shadow } from '../../Config/Images'

const ProblemSection = () => {
  return (
    <div className="relative bg-primary xl:px-36 md:px-24 px-12 xl:pt-9 pt-4 pb-40 flex justify-center">
      <div className='flex flex-col max-w-[1440px] justify-center'>
        <div className="flex flex-col lg:gap-y-3 gap-y-1 justify-center items-center xl:py-5 py-2">
          <div className="flex justify-center items-center">
            <p className="font-jersey xl:text-10xl lg:text-8xl md:text-7xl text-5xl leading-150 tracking-tightest">
              PROBLEM
            </p>
            <div className="sm:pl-6 pl-0">
              <p className="font-jersey xl:text-5xl lg:text-4xl md:text-3xl text-2xl text-start tracking-tightest xl:leading-9 leading-6">
                with
              </p>
              <p className="bg-section_best font-jersey xl:text-5xl lg:text-4xl md:text-3xl text-2xl text-white text-start tracking-tightest xl:leading-9 leading-6">
                creators
              </p>
              <p className="font-jersey xl:text-5xl lg:text-4xl md:text-3xl text-2xl text-start tracking-tightest xl:leading-9 leading-6">
                businesses
              </p>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="font-istok xl:text-xl md:text-base text-sm font-bold text-section_at text-center md:leading-6 leading-5 md:w-1/2 w-full">
              These days everyone calls himself a website creator.
              There are tons of tools and templates that allow
              entering the business with ease, but is that
              evolving somewhere? No. Read below to find out why.
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-y-6">
          <div className="sm:col-span-5 col-span-12 bg-section_maincard  rounded-3xl border border-box_color">
            <div className="flex justify-around items-center bg-orange_100  rounded-3xl border border-box_color min-h-[115px]">
              <p className="font-jersey text-purple_700 2xl:text-6xl lg:text-3xl md:text-2xl text-xl tracking-tightest">
                No transparency.
              </p>
              <div>
                <img
                  src={Circle}
                  className="h-full w-full"
                  alt="Icon"
                />
              </div>
            </div>
            <div className="flex flex-col xl:gap-y-10 md:gap-y-8 gap-y-4 xl:px-12 px-6 xl:py-11 py-6">
              <p className="font-istok xl:text-xl md:text-base text-sm text-letter tracking-tightest">
                Hidden Costs: Many web design agencies and
                platforms advertise low prices but often don’t
                disclose the full costs upfront. For instance,
                the initial quote might not include essential
                services like hosting, maintenance, SEO, or
                revisions, leading to unexpected expenses.
              </p>
              <p className="font-istok xl:text-xl md:text-base text-sm text-letter tracking-tightest">
                Agencies might use technical jargon that clients
                don’t understand, making it hard for them to
                grasp what they are paying for.
              </p>
            </div>
          </div>
          <div className="relative sm:flex hidden justify-center col-span-2">
            <div className="absolute -bottom-2/3 font-jersey xl:text-300 text-200 tracking-tightest">
              +
            </div>
          </div>
          <div className="flex flex-col justify-between gap-3 sm:col-span-5 col-span-12">
            <div className="relative flex justify-end items-center h-full border border-black rounded-3xl bg-cyan_600 sm:aspect-auto aspect-flower max-h-[115px]">
              <div className="absolute bottom-0 left-0 z-0 sm:h-full h-auto sm:w-auto w-1/2">
                <img
                  src={Flower}
                  className="h-full w-full"
                  alt="Flower"
                />
              </div>
              <p className="font-jersey 2xl:text-7xl lg:text-5xl text-4xl text-white tracking-tightest md:pr-7 pr-3 z-10">
                Lack of passion
              </p>
            </div>
            <div className="flex flex-col xl:gap-y-10 md:gap-y-8 gap-y-4 border border-black rounded-3xl bg-cyan_100 xl:p-12 p-6">
              <p className="font-istok xl:text-xl md:text-base text-sm text-gray_900 tracking-tightest">
                Many web design companies and platforms
                prioritize efficiency and scalability by using
                templates and pre-built themes. While this
                speeds up the process and reduces costs, it can
                limit creativity and lead to cookie-cutter
                websites.
              </p>
              <p className="font-istok xl:text-xl md:text-base text-sm text-gray_900 tracking-tightest">
                As the industry has grown, some companies treat
                website creation as a mass-produced service
                rather than a craft.
              </p>
            </div>
          </div>
          <div className="flex-col justify-between flex sm:hidden sm:gap-0 gap-4 sm:col-end-13 sm:col-span-5 col-span-12 pb-3">
            <div className="flex justify-between items-center border border-black rounded-3xl bg-orange_200 pl-5 md:pl-0">
              <p className="font-jersey 2xl:text-7xl lg:text-4xl md:text-3xl text-2xl text-red_700 tracking-tightest py-6">
                No innovation
              </p>
              <div>
                <img
                  src={Chain}
                  className="h-full w-full"
                  alt="Flower"
                />
              </div>
            </div>
            <div className="border border-black rounded-3xl xl:p-12 p-6">
              <p className="font-istok xl:text-xl text-base text-gray_900 tracking-tightest">
                Many web design companies and platforms
                prioritize efficiency and scalability by using
                templates and pre-built themes.
              </p>
            </div>
          </div>
          <div className="sm:col-span-5 col-span-12 bg-zinc_950 rounded-t-3xl border  border-x-box_color border-t-box_color border-b-zinc_950 ">
            <div className="flex items-center bg-small_linear rounded-3xl border border-box_color justify-between px-5 min-h-[115px]">
              <div>
                <img
                  src={Circle}
                  className="h-full w-full"
                  alt="Icon"
                />
              </div>
              <p className="font-jersey text-gray_400 2xl:text-6xl lg:text-3xl md:text-2xl text-xl tracking-tightest">
                Less empathy
              </p>
            </div>
            <div className="flex flex-col xl:gap-y-10 md:gap-y-8 gap-y-4 xl:px-12 px-6 xl:pt-13 pt-9">
              <p className="font-istok xl:text-xl md:text-base text-sm text-white tracking-tightest">
                Today's web design and development industry
                often prioritizes quick results and trendy
                aesthetics over user needs, leading to less
                empathy in design. With the rise of
                template-based platforms and automated tools,
                the focus has shifted to efficiency rather than
                personalized experiences.
              </p>
            </div>
          </div>
          <div className="hidden sm:flex flex-col justify-between sm:gap-3 gap-4 sm:col-end-13 sm:col-span-5 col-span-12 pb-3">
            <div className="flex justify-between items-center border border-black rounded-3xl bg-orange_200 px-6 min-h-[115px]">
              <p className="font-jersey 2xl:text-[72px] lg:text-4xl md:text-3xl text-2xl text-red_700 tracking-tightest py-6">
                No innovation
              </p>
              <div>
                <img
                  src={Chain}
                  className="h-full w-full"
                  alt="Flower"
                />
              </div>
            </div>
            <div className="border border-black rounded-3xl xl:p-12 p-6">
              <p className="font-istok xl:text-xl md:text-base text-sm text-gray_900 tracking-tightest">
                True innovation is often stifled by a reliance
                on ready-made templates and repetitive trends.
                In the rush to meet deadlines, unique ideas can
                be overlooked in favor of quick, familiar
                solutions.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-zinc_950  sm:rounded-tr-3xl border-zinc_950 xl:px-12 px-6 py-12">
          <p className="font-istok xl:text-xl md:text-base text-sm text-white tracking-tightest 2xl:pr-72 lg:pr-56 sm:pr-20 pr-0">
            Additionally, the fast-paced demand for constant
            innovation can make it challenging for designers to slow
            down and consider the real emotions and challenges of
            users. As a result, user-centric, thoughtful design
            sometimes takes a backseat to spend and scalability.
          </p>
        </div>
        <div className="relative flex justify-center bg-zinc_950 xl:p-14 p-11 rounded-b-1/2 shadow-2xl"
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.5) 0px 19px 38px, rgba(0, 0, 0, 0.5) 0px 12px 20px'
          }}
        >
          <div className="absolute sm:-bottom-2/3 xs:-bottom-[40%] 3xs:-bottom-[30%] -bottom-[25%] sm:w-auto w-3/4">
            <img src={Fall} className="h-full w-full" alt="Fall" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProblemSection