import React from 'react'
import { SECOND_CARD } from '../../Config/CardConstant';
import { Bird, Ghost, Star } from '../../Config/Images';

const EqualsSection = () => {
  return (
    <div className="relative bg-linear xl:px-36 md:px-24 px-12 pt-16 flex justify-center">
      <div className='flex flex-col max-w-[1440px]'>
        <div className="absolute top-0 right-0 z-0">
          <img src={Star} className="h-full w-full" alt="Star" />
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
          <div className="z-50">
            <div className="w-full h-full md:aspect-auto aspect-city relative rounded-3xl z-10 bg-city_img bg-cover bg-center border-8 border-city bg-city" />
          </div>
          <div className="flex flex-col xl:gap-y-12 gap-y-6 z-10">
            <div>
              <p className="font-jersey xl:text-64 md:text-4xl text-3xl text-white xl:!leading-64 !leading-none tracking-tightest">
                Our team specializes in creating
              </p>
              <div className='flex'>
                <span className="font-jersey xl:text-64 md:text-4xl text-3xl text-main_red xl:!leading-64 !leading-none tracking-tightest">
                  responsive
                </span>
                <span className="font-jersey xl:text-64 md:text-4xl text-3xl text-white xl:!leading-64 !leading-none tracking-tightest">
                  ,
                </span>
                <p className="font-jersey xl:text-64 md:text-4xl text-3xl text-lightBlue xl:!leading-64 !leading-none tracking-tightest ml-3">
                  user-friendly
                </p>
              </div>
              <p className="font-jersey xl:text-64 md:text-4xl text-3xl text-white xl:!leading-64 !leading-none tracking-tightest">
                websites tailored to your
              </p>
              <p className="font-jersey xl:text-64 md:text-4xl text-3xl text-white xl:!leading-64 !leading-none tracking-tightest">
                business needs.
              </p>
            </div>
            <div className="grid sm:grid-cols-2 grid-cols-1 xl:gap-6 gap-4">
              {SECOND_CARD.map((item, index) => {
                return (
                  <div
                    className="bg-box_linear sm:rounded-3xl rounded-2xl p-1"
                    key={index}
                  >
                    <div className="bg-card_linear flex flex-col xl:gap-y-6 gap-y-4 xl:p-6 p-4 sm:rounded-3xl rounded-2xl h-full">
                      <p className="font-istok xl:text-xl text-base font-bold text-card leading-none tracking-tightest">
                        {item.title}
                      </p>
                      <p className="font-istok xl:text-[18px] text-xs text-white leading-6">
                        {item.content}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="relative py-6 z-10">
          <div className="sm:rounded-3xl rounded-2xl border-4 border-ghost overflow-hidden">
            <img
              src={Ghost}
              className="h-full w-full"
              alt="Ghost"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EqualsSection