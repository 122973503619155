import React from 'react'
import { Blog, CloudOne, CloudThree, CloudTwo, Community } from '../../Config/Images'

const PerceptionSection = () => {
  return (
    <div className="bg-primary xl:px-36 md:px-24 px-12 2xl:py-48 xl:py-36 md:py-24 py-12 flex justify-center">
      <div className='flex flex-col max-w-[1368px]'>
        <div className="text-center font-jersey 2xl:text-64 xl:text-5xl md:text-4xl text-3xl leading-none tracking-tightest">
          <p>Expand your perception</p>
          <p> with our content</p>
        </div>
        <div className="grid md:grid-cols-3 grid-cols-1 xl:gap-x-20 gap-x-16 2xl:pt-16 md:pt-10 pt-5 md:gap-y-0 gap-y-4">
          <div className="flex flex-col 2xl:gap-y-6 md:gap-y-3 gap-y-1">
            <div className="flex items-center">
              <div>
                <img
                  src={Blog}
                  className="lg:h-full h-2/3 xl:w-full w-2/3"
                  alt="Blog"
                />
              </div>
              <p className="font-inter xl:text-base text-xs text-slate_200 lg:pl-2 pl-1">
                Blog
              </p>
            </div>
            <div>
              <img
                src={CloudOne}
                className="h-full w-full"
                alt="Cloud"
              />
            </div>
            <div className="2xl:p-6 p-2">
              <div className="font-istok font-bold 2xl:text-xl text-base tracking-tightest hover:underline hover:underline-offset-4 xl:py-7 py-3 border-b border-slate_50 hover:text-header_button cursor-pointer">
                <p className="w-fit">
                  Complete guide to lead generation
                  outsourcing in B2B
                </p>
              </div>
              <div className="font-istok font-bold 2xl:text-xl text-base tracking-tightest hover:underline hover:underline-offset-4 xl:py-7 py-3 border-b border-slate_50 hover:text-header_button cursor-pointer">
                <p className="w-fit">
                  B2B appointment setting costs & pricing
                  models explained
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col 2xl:gap-y-6 gap-y-3">
            <div className="flex items-center">
              <div>
                <img
                  src={Community}
                  className="lg:h-full h-2/3 xl:w-full w-2/3"
                  alt="Community"
                />
              </div>
              <p className="font-inter xl:text-base text-xs text-slate_200 lg:pl-2 pl-1">
                Community
              </p>
            </div>
            <div>
              <img
                src={CloudTwo}
                className="h-full w-full"
                alt="Cloud"
              />
            </div>
            <div className="2xl:p-6 p-2">
              <div className="font-istok font-bold 2xl:text-xl text-base tracking-tightest hover:underline hover:underline-offset-4 xl:py-7 py-3 border-b border-slate_50 hover:text-header_button cursor-pointer">
                <p className="w-fit">
                  SEO podcast: behind a successful sale in
                  SAAS industry
                </p>
              </div>
              <div className="font-inter font-bold 2xl:text-xl text-base hover:underline hover:underline-offset-4 hover:text-header_button cursor-pointer tracking-tightest xl:py-7 py-3 border-b border-slate_50 w-fit">
                Design portfolio section
              </div>
              <div className="font-istok font-bold 2xl:text-xl text-base tracking-tightest hover:underline hover:underline-offset-4    xl:py-7 py-3 hover:text-header_button cursor-pointer w-fit">
                A group chat about optimizing B2B lead
                generation campaigns
              </div>
            </div>
          </div>
          <div className="flex flex-col 2xl:gap-y-6 gap-y-3">
            <div className="flex items-center">
              <div>
                <img
                  src={Blog}
                  className="lg:h-full h-2/3 xl:w-full w-2/3"
                  alt="Blog"
                />
              </div>
              <p className="font-inter xl:text-base text-xs text-slate_200 lg:pl-2 pl-1">
                Resources
              </p>
            </div>
            <div>
              <img
                src={CloudThree}
                className="h-full w-full"
                alt="Cloud"
              />
            </div>
            <div className="2xl:p-6 p-2">
              <div className="font-istok font-bold 2xl:text-xl text-base tracking-tightest hover:underline hover:underline-offset-4 xl:py-7 py-3 border-b border-slate_50 hover:text-header_button cursor-pointer">
                <p className="w-fit">
                  Complete guide to lead generation
                  outsourcing in B2B
                </p>
              </div>
              <div className="font-istok font-bold 2xl:text-xl text-base tracking-tightest hover:underline hover:underline-offset-4 xl:py-7 py-3 border-b border-slate_50 hover:text-header_button cursor-pointer">
                <p className="w-fit">
                  B2B appointment setting costs & pricing
                  models explained
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PerceptionSection