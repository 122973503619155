import Beast from "../Assets/Img/beast.png";
import Flame from "../Assets/Img/flame.jpg";
import Development from "../Assets/Img/development.png";
import Design from "../Assets/Img/design.png";
import Man from "../Assets/Img/man.jpg";
import Woman from "../Assets/Img/woman.jpg";
import Man_Two from "../Assets/Img/marketing.png";
import Woman_Two from "../Assets/Img/team_member.png";
import PrimaryLogo from "../Assets/Img/primaryLogo.png";
import SecondaryLogo from "../Assets/Img/secondaryLogo.png";
import FirstPlane from "../Assets/Img/plane_one.png";
import SecondPlane from "../Assets/Img/plane_two.png";
import ThirdPlane from "../Assets/Img/plane_three.png";

export const IMG_ITEMS = [
    { id: "1", name: Woman },
    { id: "2", name: Man },
    { id: "3", name: Flame },
    { id: "4", name: Beast },
];

export const LOGO_ITEMS = [
    { id: "1", name: PrimaryLogo },
    { id: "2", name: SecondaryLogo },
    { id: "3", name: PrimaryLogo },
    { id: "4", name: SecondaryLogo },
    { id: "5", name: PrimaryLogo },
];

export const CAROUSEL_ITEMS = [
    {
        image: FirstPlane,
        isFullImage: true,
    },
    {
        image: SecondPlane,
        isFullImage: true,
    },
    {
        image: ThirdPlane,
        isFullImage: true,
    },
    {
        image: FirstPlane,
        isFullImage: true,
    },
];

export const LETTER_ITEMS = [
    {
        id: "1",
        letter: "GL",
    },
    {
        id: "2",
        letter: "O",
    },
    {
        id: "3",
        letter: "BE",
    },
];

export const TEAM_ITEMS = [
    {
        id: "1",
        title: "CEO",
        first_content: [
            "As the CEO of Joker Web Design, I lead our team in delivering exceptional web design and development services, ensuring each project aligns with our clients' unique visions and goals. I oversee strategic planning and business development, fostering growth and innovation within the company. My role involves maintaining strong client relationships, understanding their needs, and translating them into effective digital solutions.",
        ],
        second_content: [],
        name: "Monica",
        img: Woman_Two,
        selected: true,
    },
    {
        id: "2",
        title: "Development",
        first_content: [
            "As a developer at Joker Web Design, I bring client visions to life by building dynamic, responsive websites that blend functionality with aesthetics. I collaborate closely with designers and project managers to ensure seamless execution from concept to launch. My role involves writing clean, efficient code and troubleshooting technical challenges to enhance user experience.",
        ],
        second_content: [
            "Building dynamic responsive websites",
            "Collaborate closely with designers and project managers.",
        ],
        name: "Lamos",
        img: Development,
        selected: false,
    },
    {
        id: "3",
        title: "Design",
        first_content: [
            "I create visually captivating and user-focused designs that align with our clients' brand identities and goals. I work closely with developers and project managers to ensure a cohesive and seamless user experience across every project.",
        ],
        second_content: [],
        name: "Carl",
        img: Design,
        selected: false,
    },
    {
        id: "4",
        title: "Marketing",
        first_content: [
            "As a marketer at Joker Web Design, I craft strategies that amplify our clients' brands, driving engagement and measurable growth. I work alongside designers and developers to ensure cohesive messaging and impactful campaigns across digital platforms.",
        ],
        second_content: [
            "Analyzing audience insights",
            "Optimizing content",
            "Managing campaigns to achieve maximum reach and conversion.",
        ],
        name: "Paul",
        img: Man_Two,
        selected: false,
    },
];
