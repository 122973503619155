import React from 'react'
import { Fish, GhostMan, RightFish } from '../../Config/Images';
import { THIRD_CARD } from '../../Config/CardConstant';
import { Symbol } from '../../Element/SeverSectionCardSymbol';

const DigitalArtSection = () => {
  return (
    <div className='flex flex-col gap-5 md:gap-0'>
      <div className="bg-primary xl:px-36 md:px-24 px-12 flex justify-center">
        <div className="flex justify-between sm:pt-24 pt-12 w-full max-w-[1440px]">
          <div className='flex flex-col gap-7 md:gap-0'>
            <p>
              <span className="font-jersey 2xl:text-6xl sm:text-4xl text-2xl text-gray_800 md:leading-64 tracking-tightest">
                Today’s consumers are
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className="font-jersey 2xl:text-6xl sm:text-4xl text-2xl text-red_800 md:leading-64 tracking-tightest">
                digital experts
              </span>
            </p>
            <p className="sm:pt-2 pt-1">
              <span className="bg-red_800 font-finger text-white 2xl:text-7xl sm:text-5xl text-4xl 2xl:leading-132 leading-none tracking-tightest sm:pl-9 pl-3 sm:pr-4 pr-1">
                You own
              </span>
              &nbsp;
              <span className="bg-primary font-finger 2xl:text-4xl sm:text-2xl text-lg text-gray_300 2xl:leading-94 lg:leading-none leading-8 tracking-tightest">
                of they own you.
              </span>
            </p>
          </div>
          <div className="relative lg:block hidden 2xl:w-auto w-1/4">
            <img src={Fish} className="h-full w-full" alt="Fish" />
            <div className="absolute xl:bottom-0 -bottom-6 -left-[28px]">
              <img
                src={RightFish}
                className="h-full w-full"
                alt="RightFish"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative bg-beach flex flex-col md:gap-[90px] bg-cover bg-no-repeat w-full z-0 xl:px-36 md:px-24 px-12 lg:pb-15 pb-40 bg-primary justify-center items-center">
        <div className='flex flex-col max-w-[1440px]'>
          <div className='flex flex-col gap-6'>
            <p className="font-istok xl:text-2xl text-base font-bold leading-6 tracking-tightest">
              They explore, they engage, they buy! Ensure your website is ready to impress.
            </p>
            <p className="font-istok xl:text-xl md:text-base text-sm tracking-tightest md:pt-0 pt-2">
              Our impactful designs elevate strong brands to legendary status, making a lasting impression on every visitor who interacts with them.
              As a versatile digital design agency, we create thoughtfully crafted solutions that go beyond achieving business objectives and earn industry recognition.
            </p>
            <p className="font-istok xl:text-2xl text-base font-bold leading-6 tracking-tightest">
              What makes us different?
            </p>
          </div>
          <div className="relative flex z-10 md:gap-[120px] md:flex-row flex-col">
            {THIRD_CARD.map((item, index: number) => {
              return (
                <div
                  key={index}
                  className={`${index === 0 && "lg:col-start-2 col-start-1"
                    } flex-1`}
                >
                  <span className="font-jersey 2xl:text-4xl sm:text-4xl text-2xl text-gray_800 leading-64 tracking-tightest">
                    {item.title}
                  </span>
                  <div className="rounded-3xl border border-black overflow-hidden">
                    <img
                      src={item.img}
                      className="h-auto w-full"
                      alt="Beach"
                    />
                  </div>
                  <p className="font-istok xl:text-md md:text-base text-sm md:py-6 py-2 tracking-tightest">
                    {item.content}
                  </p>
                </div>
              );
            })}
          </div>
          <a
            className="bg-header_button hover:bg-red-300 cursor-pointer text-white xl:px-13 sm:px-10 px-6 xl:py-3.5 sm:py-3 py-1 max-w-[400px] rounded-[30px] self-end"
            // onClick={props.toggleModal}
            href="mailto:info@jokerwebdesign.com"
          >
            <p className="xl:text-64 lg:text-5xl xs:text-4xl text-3xl leading-none font-jersey">
              Get a Quote
            </p>
          </a>
        </div>
        <div className="absolute xs:-bottom-20 bottom-0 xl:left-20 lg:left-36 md:left-2/3 xl:w-full lg:w-2/3 w-1/3 max-w-[393px] max-h-[279px]">
          <img src={GhostMan} className="h-auto" alt="GhostMan" />
        </div>
      </div>
    </div>
  )
}

export default DigitalArtSection